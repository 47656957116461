// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: giant; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $dark; // Navigation Background
$navColor: $light; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
	position: relative;
	@include breakpoint($break) {
		padding: 1em 0.2rem;
	}
	@include breakpoint($break, max) {
		border-bottom: 1px solid rgba($light, 0.1);
	}
	&:before {
		content: '';
		position: absolute;
		left: 0;
		bottom: -1px;
		width: 100%;
		height: 2px;
		background: $primary;
		opacity: 0;
		z-index: 2;
		transition-property: opacity;
		transition-duration: 0.4s;
		@include breakpoint($break) {
			bottom: 14px;
		}
	}
}
%buttonHover {
	// Link Hover
	&:before {
		opacity: 1;
	}
}
%buttonActive {
	// Link Active
	
	&:before {
		opacity: 1;
	}
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotate(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		content: map-get($iconMap, corner-down);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 60px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $darker; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;
	@include breakpoint($break, max) {
		transition-property: opacity, transform;
		transition-duration: 0.4s;
	}

	nav {
		@include ul {
			&.navi {
				@include breakpoint(large, max) {
					padding-bottom: 4rem;
				}
				li {
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							background: $navBg;
							transition-property: opacity, transform;
							transition-duration: 0.4s;
							padding: 0 1rem;
							@include breakpoint($break, max) {
								height: 100%;
								z-index: 5;
							}

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
								color: $light;
							}
						}
					}
					@include navBack {
					}
					a.login {
						@extend .btn;
						background: $darker;
						padding: 12px 20px;
						margin-top: 1.5rem;
						@include breakpoint(giant) {
							font-size: clamp(13px, 1.1vw, 16px);
							margin-top: 0;
						}
						@include breakpoint(huge) {
							font-size: clamp(14px, 1vw, 16px);
						}
						&:before {
							display: none;
						}
					}
				}
			}
		}
	}
	.bilder {
		padding-bottom: 35rem;
		@include breakpoint(giant) {
			padding-bottom: 0;
		}
	}
}




// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		height: 100px;
		font-size: clamp(13px, 1.1vw, 16px);
		display: flex;
		align-items: center;
		@include breakpoint(huge) {
			font-size: clamp(14px, 1vw, 16px);
		}
		nav {
			ul {
				&.navi {
					li {
						&.hasSub {
							.sub {
								a {
									@include breakpoint($break) {
										padding: 1em 1rem;
									}
								}
							}
						}
						&.navBack {
						}
						&.startseite {
							@include breakpoint(huge, max) {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}

	a.btn {		
		padding: 8px 20px;
		font-size: 13px;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {
	display: flex;
	justify-content: flex-start;
	@include breakpoint(small) {
		font-size: 14px;
	}
	@include breakpoint(medium) {
		font-size: 16px;
	}
	@include breakpoint(small, max) {
	    flex-direction: column;
		align-items: center;

		a {
			display: block;
			text-align: center;
			margin: 0 auto;
			max-width: 300px;
			padding: 8px 0;
			// border-bottom: 1px solid rgba($light, 0.2);
		}
	}
	
	li {
		margin-right: 1rem;
		@include breakpoint(large) {
			margin-right: 2rem;
		}
		@include breakpoint(small, max) {
			margin-right: 0;
			width: 100%;
		}
	}
}

a.login {
	@include breakpoint($break, max) {
		margin-left: rem(21px);
	}
}
.marginBottom {
  margin-bottom: rem($baseLineHeight);
}

.highlight {
  color: $secondary;
}

.btn {
  @extend %animatedTransform;
  background: $dark;
  border: none;
  color: $light;
  cursor: pointer;
  display: inline-block;
  font-size: rem($baseFontSize);
  line-height: rem($baseLineHeight);
  padding: rem(17px) rem(29px);
  text-align: center;
  text-decoration: none;
  font-family: $mainFont;
  border-radius: 40px;
  border-bottom-left-radius: 0;

  &:hover {
    background: lighten($dark, 15%);
    color: $light;
  }
  &:active {
    background: lighten($dark, 30%);
    color: $light;
  }
  &:focus {
    background: lighten($dark, 45%);
    color: $light;
  }

  *[class^="icon"] {
    margin-left: 0.5rem;
    vertical-align: middle;
  }
  &:after {
    font-family: $iconFont;
    content: map-get($iconMap, angle-right); 
    margin-left: 0.5rem;
    vertical-align: middle;   
  }
  &.special {
    margin: 2rem 0 4rem;
  }
}

.clear {
  clear: both;
  float: none;
}

.clearfix {
  @include pie-clearfix;
}

.hidden {
  display: none !important;
}

.hideText {
  @include hideText();
  display: inline-block;
  overflow: hidden;
  width: 0;
}

.floatLeft {
  float: left;
}

img.floatLeft {
  margin-right: 1rem;
}

.floatRight {
  float: right;
}

img.floatRight {
  margin-left: 1rem;
}

.fluid {
  height: auto;
  width: 100%;
}

.nonFluid {
  width: auto !important;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.wide {
  width: 100%;
  display: block;
}

/* 16:9 video resolutions */
.videoContainer {
  &:not(.videoTag) {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 42.6%;
    position: relative;

    iframe {
      border: none;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  video {
    width: 100%;
    height: auto;
  }
}
.googleMaps {
  @extend .videoContainer;

  @include breakpoint(medium) {
    height: rem(400px);
  }
}

body {
  &:before {
    $content: "default:" + $bpContext;
    width: str_length($content) * 6pt + $baseGap * 2;

    @each $point, $width in $gridMap {
      $content: $content + "...." + $point + ":" + nth(map-get(map-get($gridMap, $point), width), 1);

      @include breakpoint($point) {
        width: str_length($content) * 6pt + $baseGap * 2;
      }
    }

    content: "#{$content}";
    display: none !important; /* Prevent from displaying. */
  }

  &.devmode:before,
  &.debug:before {
    background: $alert;
    border-color: $alert;
    border-style: solid;
    border-width: 0 rem($baseGap);
    color: $light;
    display: block !important;
    font-family: Courier;
    font-size: 10pt;
    left: 0;
    line-height: 2.5;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    white-space: nowrap;
    z-index: 99999;
  }
  &:hover:before {
    opacity: 0.5;
    pointer-events: none;
  }
}

// Animations
%animatedTransform {
  transition: 0.3s;
}

html.inProgress {
  pointer-events: none;
  &:before {
    content:"";
    width: 100vw;
    height: 100vh;
    background:rgba(0,0,0,0.5);
    position: fixed;
    top:0;
    left:0;
    z-index:99999998
  }
  &:after {
    content:"";
    position: fixed;
    top: 0;
    height: 0;
    background: url('/images/layout/loading.svg') no-repeat center center;
    height: 100vh;
    width: 100vw;
    z-index:99999999;
    filter:invert(100%);
  }
}

.border {
  background: $medium;
  border-bottom-right-radius: 50px;
  margin-bottom: 2rem;
  height: calc(100% - 2rem);
  @include breakpoint(giant) {
    border-bottom-right-radius: 100px;
    margin-bottom: 4rem;
    height: calc(100% - 4rem);
  }
  img {
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 0px;
    @include breakpoint(giant) {
      border-bottom-right-radius: 100px;
    }
  }
  .abstaende {
    padding: 1.5rem 1rem 0rem;
    // @include breakpoint(tiny) {
    //   padding: 1.5rem 0rem 0rem;
    // }
    @include breakpoint(small) {
      padding: 1.5rem 1rem 0rem;
    }
    @include breakpoint(huge) {
      padding: 1.5rem 1rem 0rem 2rem;
    }
  }
}

.margin-big {
  margin-bottom: 6rem;
  @include breakpoint(small) {
    margin-bottom: 8rem;
  }
  @include breakpoint(giant) {
    margin-bottom: 150px;  
  }
}

.inner-padding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @include breakpoint(giant) {
  padding-top: 120px;
  padding-bottom: 102px;
  }
  &.left-side {
    @include breakpoint(huge) {
    padding-left: 3rem;
    }
    &:after {
      @include breakpoint(giant) {
        content: '';
        position: absolute;
        background: $medium;
        left: -1000px;
        top: 0;
        bottom: 0;
        right: 94%;
      }
      @include breakpoint(huge) {
        right: calc(100% - 8.33333%);
      }
    }
  }
  &.right-side {
    @include breakpoint(huge) {
    padding-left: 3rem;
    padding-right: 3rem;
    }
    &.no-left {
      padding-left: 1.125rem
    }
    &:after {
      @include breakpoint(giant) {
        content: '';
        position: absolute;
        background: $medium;
        right: 0;
        top: 0;
        bottom: 0;
        left: 94%;
        z-index: -2;
      }
      @include breakpoint(huge) {
        left: calc(100% - 8.33333%);
      }
      @include breakpoint(cut) {
        left: 10rem;
      }
    }
    &:before {
      @include breakpoint(cut) {
        content: '';
        position: absolute;
        background: $light;
        right: 0;
        top: 0;
        bottom: 0;
        right: auto;
        z-index: -1;
        left: 10rem;
        max-width: 1320px;
        width: 100%;
      }
    }
    .max-width {
      @include breakpoint(large) {
      max-width: 714px;
      &.zweispaltig {
        max-width: 1380px;        
      }
      }
    }
  }
}

.block {
  display: block !important;
  margin-top: -1rem;
}

.file {
  margin-bottom: 0.8rem;
  display: inline-block;
  &:before {
    content: '';
    background-image: url('/images/layout/file-pdf-o.svg');
    position: relative;
    display: inline-block;
    margin-right: 0.4rem;
    width: 16px;
    height: 20px;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
div#pageWrap {
	overflow: hidden;
	padding-top: $barHeight;
	@include breakpoint($break) {
		padding-top: rem(100px);
	}
}
// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;
	margin-bottom: 3rem;
	body.index & {
		@include breakpoint(giant) {
			margin-bottom: rem(150px);
		}
	}
	body:not(.index) & {
		.breakRight {
			max-width: 101.25rem;
			margin-right: auto;
		}
		.large-6,
		.giant-5 {
			@include breakpoint(large) {
				max-width: 100%;
				flex-basis: 100%;				
			}
		}
	}

	.branding-flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		@include breakpoint(small) {
			max-width: 480px;
    		margin: 0 auto;
		}
		@include breakpoint(large) {
			max-width: 450px;
			align-items: flex-start;
		}
		.branding:not(.smiledesign) {
			@include breakpoint(giant) {
				margin-top: 2rem;
			}
			@include breakpoint(tiny, max) {
				margin-top: 2rem;
			}
		}
		@include breakpoint(huge) {
			max-width: 78%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: nowrap;
			flex-direction: row;
			margin-left: 0;
			.branding {
				margin-right: 1rem;
			}
			.branding:not(.smiledesign) {
				margin: 0;
			}
		}
		body:not(.index) & {
			@include breakpoint(large) {
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-direction: row;
				margin: 0 auto;
			}
			@include breakpoint(giant) {
				max-width: 520px;			
			}
		}
	}

	.branding {
		display: block;
		height: 80px;
		width: auto;
		margin: 0 auto;
		position: relative;
		z-index: 1;
		@include breakpoint(large) {
			margin: 0;
		}
		@include breakpoint(giant) {
			height: 90px;		
		}
		&.smiledesign {
			padding: 0 1rem;
		}

		img {
			width: auto;
			height: 100%;
			margin: 0 auto;
			display: block;
		}
	}

	.claim-box {
		margin-top: rem(120px);
		background: $medium;
		z-index: 5;
		position: relative;
		@include breakpoint(large) {
			margin-top: rem(90px);
			width: calc(100% + 4.5rem);
		}
		.claim {
			font-size: rem(30px);
			font-weight: 700;
			line-height: 1.2em;
			padding: 2rem;
			position: relative;
			@include breakpoint(tiny) {
				padding: 3rem 2rem;
			}
			@include breakpoint(small) {			
				padding: 3rem 2rem 3rem 5rem;
			}
			@include breakpoint(medium) {
			    font-size: clamp(30px, 4.1vw, 40px);
				line-height: 1.2em;
			}
			@include breakpoint(large) {
				padding: 3rem 2rem;
				font-size: clamp(30px, 3.3vw, 40px);
				line-height: 1.2em;
			}
			@include breakpoint(giant) {			
				font-size: clamp(30px, 2.8vw, 40px);
				line-height: 1.2em;
			}
			@include breakpoint(huge) {
				padding: rem(77px) 2rem;
			}
			@include breakpoint(cut) {
				padding: rem(77px);
			}
			span {
				position: relative;
				z-index: 5;
			}
			span:nth-child(2) {
				display: block;
				font-size: rem(18px);
				font-weight: 300;
				line-height: 1.4em;
				margin-top: 5px;
				@include breakpoint(medium) {
					font-size: clamp(18px, 2.4vw, 26px);
					line-height: 1.2em;
				}
				@include breakpoint(large) {
					font-size: clamp(18px, 2.1vw, 26px);
					line-height: 1.2em;
				}
				@include breakpoint(giant) {
					font-size: clamp(18px, 1.8vw, 26px);
					line-height: 1.2em;
				}
			}
			&:after {
				content: '';
				background-image: url('/images/layout/teeth-header.svg');
				position: absolute;
				bottom: 0;
				width: 500px;
				height: calc(100% + 3.68rem);
				background-repeat: no-repeat;
				background-size: contain;
				background-position: bottom left;
				z-index: 0;
				left: -2.3rem;
				@include breakpoint(large) {
					width: 100%;
					left: rem(-106px);
				}
			}
		}
	}
	.dark-bg {
		color: $secondary;
		@include breakpoint(small) {
			display: flex;
			justify-content: space-between;
		}
		@include breakpoint(large) {
			font-size: clamp(13px, 1.2vw, 16px);
		}
		@include breakpoint(cut) {
			padding: 3rem rem(77px);
		}
		.title {
			font-size: 20px;
			color: $light;
			font-weight: 600;
			margin-bottom: rem(11px);
		}
		.phone,
		.email {
			display: inline-block;
		}
		.infos:first-of-type {
			@include breakpoint(small, max) {
				border-bottom: 1px solid rgba($light, 0.2);
				padding-bottom: 2rem;
				margin-bottom: 2rem;
			}
		}
	}

	picture {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 0;
		width: 100%;
		height: auto;
	}
	.col {
		position: relative;
	}
	.headerPadding {
		padding: 5rem 0 4rem;
		@include breakpoint(small) {
			padding: 5rem 0 5rem;
		}
		body:not(.index) & {
			padding: 3rem 0 1rem;
			@include breakpoint(small) {
				padding: 5rem 0 3rem;
			}
			@include breakpoint(giant) {
				padding: 6rem 0 4rem;
			}
		}
	}
	.order {
		@include breakpoint(large, max) {
			order: -1;
		}
	}
	.headerContainer {
		border-bottom-left-radius: 100px;
		position: relative;
		height: 200px;
		width: calc(100% + 1.125rem);
		@include breakpoint(small) {
			height: 300px;
		}
		@include breakpoint(large) {
			height: 100%;
			position: absolute;
			left: 1rem;
			top: 0;
			right: 0;
			bottom: 0;
		}
		@include breakpoint(giant) {
			width: calc(100% + 3rem);
			left: -3rem;
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0px;
			z-index: 0;
			right: 0;
			background: $dark;
			background: linear-gradient(90deg, rgba($darker,1) 0%, rgba($darker,0) 50%);
			opacity: 0.95;
			transition-property: opacity;
			transition-duration: 0.4s;
			z-index: 1;
		}

		.swiper-slide {
			background-size: cover;
			&.slider1 {
				background-position: 50% 10%;
				background-image: url('/images/layout/small-slider1.jpg');
				@include breakpoint(small) {
					background-image: url('/images/layout/large-slider1.jpg');
				}
				@include breakpoint(large) {
					background-image: url('/images/layout/slider1.jpg');
				}
			}
			&.lazyLoaded {
				&.slider2 {
					background-position: 50% 80%;
					background-image: url('/images/layout/small-slider2.jpg');
					@include breakpoint(small) {
						background-image: url('/images/layout/large-slider2.jpg');
					}
					@include breakpoint(large) {
						background-image: url('/images/layout/slider2.jpg');
					}
				}
				&.slider3 {
					background-position: 0% 20%;
					background-image: url('/images/layout/small-slider3.jpg');
					@include breakpoint(small) {
						background-image: url('/images/layout/large-slider3.jpg');
					}
					@include breakpoint(large) {
						background-image: url('/images/layout/slider3.jpg');
					}
				}
				&.slider4 {
					background-position: 50% 50%;
					background-image: url('/images/layout/small-slider4.jpg');
					@include breakpoint(small) {
						background-image: url('/images/layout/large-slider4.jpg');
					}
					@include breakpoint(large) {
						background-image: url('/images/layout/slider4.jpg');
					}
				}
				&.slider5 {
					background-position: 40% 25%;
					background-image: url('/images/layout/small-slider5.jpg');
					@include breakpoint(small) {
						background-image: url('/images/layout/large-slider5.jpg');
					}
					@include breakpoint(large) {
						background-image: url('/images/layout/slider5.jpg');
					}
				}
			}
		}

		.swiper-pagination {
			width: auto;
			top: 1rem;
			left: 1rem;
			bottom: auto;
			@include breakpoint(small) {
				top: 2rem;
				left: 2rem;
			}

			span.swiper-pagination-bullet {
				background: $light !important;
				height: 8px;
				margin-top: 8px;
				border-radius: 10px !important;
				width: 8px;
				transition: width 0.8s;

				&.swiper-pagination-bullet-active {
					width: 30px;
				}
			}
		}
	}
}

.dark-bg {
	background-color: $dark;
	color: $light;
	padding: 2rem;
	@include breakpoint(huge) {
		padding: 3rem 2rem;
	}
	@include breakpoint(cut) {
		padding: rem(77px);
	}
	b,
	strong {
		font-weight: 900;
	}

	.btn {
		background: $light;
		color: $dark;
	}
}

.cad-area {
	background: #eeeeee;
	position: relative;
	z-index: 5;
	&:after {
		@include breakpoint(medium) {
			position: absolute;
			content: '';
			background-image: url('/images/index/fraestechnik-bg.jpg');
			background-size: cover;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			z-index: -1;		
		}
		@include breakpoint(huge) {
			background-size: contain;
			background-position: left center;
		}
	}
	&:before {
		@include breakpoint(medium) {
			position: absolute;
			content: '';
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			background: #eeeeee;
			background: linear-gradient(90deg, rgba(#eeeeee,0) 0%, rgba(#eeeeee,1) 40%);		
		}
		@include breakpoint(giant) {
			background: linear-gradient(90deg, rgba(#eeeeee,0) 0%, rgba(#eeeeee,1) 50%);
		}
		@include breakpoint(cut) {
			display: none;
		}
	}
	.dark-bg {
		margin-top: rem(-50px);
		@include breakpoint(large) {
			margin-top: rem(-100px);
		}
	}
	.padding-left {
		padding-left: 2rem;
		padding-right: 2rem;
		padding-top: 4rem;
		padding-bottom: 2.5rem;
		@include breakpoint(small) {
			padding-top: 6rem;
			padding-bottom: 4.5rem;
		}
		@include breakpoint(large) {
			padding-top: rem(100px);
		}
		@include breakpoint(cut) {
			padding-left: rem(77px);
			padding-right: rem(77px);
		}
	}
}

.special-padding {
	@include breakpoint(small) {
	padding-right: 35%;
	}
	@include breakpoint(large) {
		padding-right: 0;
	}
}

.karriere-area {
	z-index: 6;
	position: relative;
	&:after {
        content: '';
        position: absolute;
        background: $dark;
        right: 0;
        top: 200px;
        bottom: 0;
        left: 0;
		z-index: -1;
	}
	&.modalGallery {
		&:after {
			top: 80px;
			@include breakpoint(small) {
				top: 100px;
			}
			@include breakpoint(giant) {
				top: 200px;
			}
		}
	}
	.karriere-banner {
		background-color: $medium;
		padding: 4rem 0;
		border-bottom-right-radius: 50px;
		position: relative;
		overflow: hidden;
		z-index: 5;
		@include breakpoint(tiny) {
			padding: 6rem 0;
		}
		@include breakpoint(small) {
		  border-bottom-right-radius: 100px;
		}
		@include breakpoint(large) {
			padding: rem(120px) 0;
		}
		@include breakpoint(large, max) {
		padding-left: 2rem;
		}
		&.lazyLoaded {
			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: -1;
				background-position: 50% 50%;
				background-size: cover;
				background-image: url('/images/index/tiny-karriere-banner.jpg');
				opacity: 0.3;
				@include breakpoint(tiny) {
					background-image: url('/images/index/small-karriere-banner.jpg');
					opacity: 0.6;
				}
				@include breakpoint(small) {
					background-position: 70% 50%;
					background-image: url('/images/index/large-karriere-banner.jpg');
					opacity: 1;
				}
				@include breakpoint(large) {
					background-position: 60% 50%;
					background-image: url('/images/index/karriere-banner.jpg');
				}
			}
		}
		.h1 {
			@include breakpoint(large) {
				font-size: rem(54px);
			}
		}
		p:not(.h1) {
			@include breakpoint(large) {
			font-size: rem(20px);
			}
			span {
				@include breakpoint(tiny) {
				display: block;
				}
			} 
		}
	}
}

.zahnaerztin {
	@include breakpoint(small) {
		position: absolute;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom right;
		z-index: -1;
		left: 48%;
		top: 8rem;
		bottom: -8rem;
		right: 0;	
		background-position: calc(100% - -5rem) 100%;
	}
	@include breakpoint(large) {
		top: -10rem;
		bottom: -4rem;
		left: 43%;
	}
	@include breakpoint(giant) {
		left: 52%;
	}
	@include breakpoint(huge) {
		background-position: calc(100% - -7rem) 100%;
	}
	@include breakpoint(cut) {
		background-position: bottom right;
	}
	@include breakpoint(max) {
		right: -4rem;	
	}
	&.lazyLoaded {
		background-image: url('/images/layout/doctor.jpg');
	}
}
.dark-area {
	z-index: 6;
	position: relative;
	&:after {
        content: '';
        position: absolute;
        background: $dark;
        right: 0;
        top: 200px;
        bottom: 0;
        left: 0;
		z-index: -1;
	}
	.teaserSwiper {
		padding-left: 1.125rem;
		max-height: 660px;
		&:before {
			content:'';
			right: 2rem;
			bottom: 22px;
			position: absolute;
			height: 1px;
			background: white;
			width: calc(100% - 11rem);
			opacity: 0.2;
		}
		.swiper-wrapper {
			padding-bottom: 2rem;
		}
		.teaser {
			background-size: cover;
			background-position: center;
			padding-bottom: 109%;
			border-bottom-right-radius: 50px;
			position: relative;
			overflow: hidden;
			@include breakpoint(small) {
				border-bottom-right-radius: 100px;
			}
			&:before {
				content: '';
				position: absolute;
				background: $dark;
				top: 0;
				bottom: 0;
				left: -1px;
				z-index: 2;
				width: 9px;
				background-color: $darker;
			}
			&:after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0px;
				z-index: 0;
				right: 0;
				background: $dark;
				background: linear-gradient(0deg, rgba($dark,0) 40%, rgba($dark,1) 100%);
				opacity: 0.95;
				transition-property: opacity;
				transition-duration: 0.4s;
			}
			.title {
				position: absolute;
				z-index: 5;
				left: 1.5rem;
				top: 2rem;
				font-size: clamp(18px,4vw,36px);
				font-weight: 700;
				transition-property: opacity, transform, visibility;
				transition-duration: 0.4s;
				color: $light;
				@include breakpoint(small) {
					font-size: clamp(18px,2.4vw,36px);
					left: 2rem;
					top: 2.5rem;	
				}
				@include breakpoint(medium) {
					top: rem(58px);
				}
				@include breakpoint(giant) {
					font-size: clamp(18px,2.2vw,36px);
				}
				@include breakpoint(cut) {
					font-size: 30px;
				}
				@include breakpoint(max) {
					font-size: 32px;
				}
			}
			.more {
				position: absolute;
				left: 1.5rem;
				top: 3.8rem;
				font-size: 16px;
				z-index: 5;
				transition-property: transform, font-size;
				transition-duration: 0.4s;
				color: $light;
				@include breakpoint(small) {
					left: 2rem;
					top: 4rem;	
				}
				@include breakpoint(medium) {
					top: 5.4rem;	
				}
				@include breakpoint(large) {
					top: rem(90px);
					font-size: 20px;
				}
				@include breakpoint(giant) {
					top: rem(100px);
				}
			}
			&:hover {
				.title {
					opacity: 0;
					visibility: hidden;
					transform: translateY(-1rem);
				}
				.more {
					font-size: 20px;
					transform: translateY(-1.8rem);
					@include breakpoint(small) {
						transform: translateY(-1.55rem);
					}
					@include breakpoint(large) {
						transform: translateY(-2.2rem);
						font-size: 26px;
					}
					@include breakpoint(giant) {
						transform: translateY(-2.7rem);
					}
					@include breakpoint(huge) {
						transform: translateY(-3.3rem);
						font-size: 30px;
					}
				}
				&:after {
					opacity: 0.7;					
				}
			}
			a {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: none;
				z-index: 6;
				border-bottom-right-radius: 50px;
				@include breakpoint(small) {
					border-bottom-right-radius: 100px;
				}
			}
		}

		.swiper-button-next,
		.swiper-button-prev {
			position: relative;
			z-index: 6;
			top: -5rem;
			left: auto;
			width: 50px;
			height: 50px;
			color: $light;
			margin-top: 0;
			right: auto;
			display: inline-block;
			padding: 12px;
			transition: background 0.4s;
			outline: none;
			&:after {
				font-size: 26px;
			}
			&:hover {
				background: $darker;
			}
		}
		.swiper-button-next {
			&:after {
				content: map-get($iconMap, angle-right);				
			}
		}
		.swiper-button-prev {
			&:after {
				content: map-get($iconMap, angle-left);				
			}
		}
	}
}

.karriere-box {
	border: 1px solid $border;
	padding: 2rem 1rem 0.5rem;
	@include breakpoint(tiny) {
		padding: 2rem 2rem 0.5rem;
	}
	@include breakpoint(small) {
		padding: 3rem 2rem 1.5rem;
	}
	.title {
		font-weight: 700;
		font-size: 22px;
		@include breakpoint(giant) {
			font-size: 26px;
		}
	}
}

main,
footer {
		
	a {
		background: 
			linear-gradient(to right, rgba($primary, 1), rgba($primary, 1)),
			linear-gradient(to right, rgba($border, 1), rgba($border, 1));
		background-size: 100% 2px, 0 2px;
		background-position: 100% 100%, 0 100%;
		background-repeat: no-repeat;
		transition: background-size 400ms, color 400ms;
		color: inherit;

		&:hover,
		&:focus {
			background-size: 0 2px, 100% 2px;
			color: $primary;
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	.padding {
		padding-top: 6rem;
		padding-bottom: 4rem;
		z-index: 5;
		position: relative;
		@include breakpoint(small) {
			padding-top: 6rem;
			padding-bottom: 6rem;
		}
		@include breakpoint(giant) {
			padding-top: 120px;
			padding-bottom: 120px;
		}
		body:not(.index) & {
			background-color: $medium;
			margin-top: 6rem;
			@include breakpoint(small) {
				margin-top: 6rem;
			}
			@include breakpoint(giant) {
				margin-top: 150px;
			}
			.infos {
				border: 1px solid darken($border, 5%);
			}
		}
		.infos {
			border: 1px solid $border;
			height: calc(100% - 2rem);
			padding: 2rem 1rem 2rem;
			margin-bottom: 2rem;
			@include breakpoint(small) {
				height: 100%;
				margin-bottom: 0;
			}
			@include breakpoint(large) {
				padding: 2rem 2rem 2rem;
			}
			@include breakpoint(medium, max) {
			font-size: 15px;
			}
			.branding {
				background: none;
				display: block;
				height: 80px;
				width: auto;
				margin: 0 auto;
				position: relative;
				margin-bottom: 1.5rem;
				@include breakpoint(giant) {
					height: 110px;
				}
				img {
					width: auto;
					height: 100%;
					display: block;
				}
			}
			address {
				margin-bottom: 1.375rem;
				padding-left: 23px;
				position: relative;
				.icon {
					position: absolute;
					left: 0;
					top: 5px;
				}
			}

			&.zahn {
				position: relative;
				background: $light;
				&:before {
					@include breakpoint(huge) {
						content: '';
						position: absolute;
						left: -16rem;
						top: -4rem;
						width: 600px;
						bottom: 3rem;
						background-size: contain;
						background-repeat: no-repeat;
						background-image: url('/images/layout/teeth-footer.svg');
						z-index: -1;						
					}
				}
				body:not(.index) & {
					background: $medium;
					&:before {
						background-image: url('/images/layout/teeth-footer2.svg');
					}
				}
			}
		}
	}	
	.footer-bg {
		background: $dark;
		padding: 2rem 0 4rem;
		@include breakpoint(small, max) {
			flex-direction: column;
			align-items: center;
		}
		@include breakpoint(big) {
			padding: 2rem 0 2rem;
		}
		a {		
			color: $light;
			transition: opacity 0.4s;
			background: none;
			&:hover,
			&.active {
				opacity: 0.4;
			}
		}
	
		.flexer {
			display: grid;
			place-items: center;
			@include breakpoint(small) {
				display: flex;
				justify-content: space-between;
				align-items: center;				
			}
		}
	}
	.socialWrap {
		text-align: center;
		@include breakpoint(small) {			
			display: flex;
			align-items: center;
		}
		@include breakpoint(small, max) {
			margin: 0 auto;
			margin-top: 2rem;
		}
		span {
			color: #7E7E7E;
			text-transform: uppercase;
			font-weight: 400;
			@include breakpoint(small) {
				font-size: 14px;
			}
			@include breakpoint(medium) {
				font-size: 16px;
			}
			&:after {
				content: '';
				display: block;
				position: relative;
				width: 1px;
				height: 30px;
				background: #7E7E7E;
				margin: 1rem auto;
				@include breakpoint(small) {
					height: 1px;
					width: 30px;
					display: inline-block;
					margin: 6px 0.3rem 6px 0.6rem;
				}
				@include breakpoint(medium) {
					margin: 6px 0.5rem 6px 1rem;
				}
				@include breakpoint(large) {
					width: 120px;
					margin: 6px rem(12px) 6px rem(30px);
				}
			}
		}
		.socials {
			display: flex;
			align-items: center;
			height: 26px;
			a {
				width: 26px;
				height: 26px;
				margin-left: 0.5rem;
				background: none;
				transition: opacity 400ms;
				display: flex;
				@include breakpoint(medium) {
					margin-left: 1rem;
				}
				@include breakpoint(small, max) {
					margin: 0 0.5rem;
				}
				&:hover,
				&:focus {
					opacity: 0.4;
				}
			}
		}
	}
}
.popUpContainer {
 
	body:not(.cmsBackend) & {
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		overflow: hidden;
		transition: opacity .3s, visibility .3s;
		position: fixed;
		visibility: visible;
		opacity: 1;
		z-index: 1000000;
 
		.popUpContent {
            width: 100%;
			background: $light;
			max-height: 90vh;
			max-width: calc(128% - #{5.875rem}); // for the close label
	    	overflow: hidden;
	        overflow-y: auto;
			@include breakpoint(medium) {
				max-width: calc(100% - #{rem(110px)});
			}
			@include breakpoint(giant) {
				max-width: 80%;
			}
	 
			@include breakpoint(full) {
				max-width: 70%;
			}
		}
	}
 
	label {
		display:none;
 
		body:not(.cmsBackend) & {
			background: rgba(black, 0.8);
			display: flex;
			height: 100%;
			justify-content: flex-end;
			padding: rem($baseGap);
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
			z-index: -1;
	 
			i {
				cursor: pointer;
				display: block;
				height: rem(35px);
				width: rem(35px);
				position: relative;
				transition: background .3s;
	 
				&:hover {
					background: rgba(black, 0.8);
	 
					&:before, &:after {
						background: white;
					};
				}
	 
				&:before, &:after {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					width: rem(30px);
					height: rem(2px);
					background: #ddd;
				}
	 
				&:before {
					transform: translate(-50%,-50%) rotate(45deg);
				}
	 
				&:after {
					transform: translate(-50%,-50%) rotate(-45deg);
				}
			}
		}
	}
}
 
input[id^="popUpToggled"] {
	display: none;
 
	&:not(:checked) {
 
		+ {
 
			.popUpContainer {
				position: relative;
				opacity: 0;
				visibility: hidden;
				z-index: -1000000;
				transform:scale(0);
				pointer-events: none;
				width: 0;
				height: 0;
			}
		}
	}
}
.videoBtn{
	margin-top: 30px;
    width: 90%;
	margin-bottom: 30px;
}

.btn-box {
	align-items: center;
}
.aktuelles-box {
	justify-content: center!important;
}

.needs-gap {
	padding-bottom: 2rem;
}


// Use this for default Icomoon

@include fontFaceV2(
    $fontName: "icomoon",
    $fileName: "icomoon",
    $types: (woff, ttf, svg, eot),
    $style: normal,
);

@include fontFaceV2(
    $fontName: "Merriweather Sans", 
    $fileName: "merriweather-sans-v20-latin", 
    $weights: ("300", "400", "700", "800"), 
    $types: all
);
* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in config
html {
  background: $light;
  box-sizing: border-box;
  color: $dark;
  font-family: $mainFont;
  font-size: 100% * ($baseFontSize / 16px);
  hyphens: manual;
  line-height: rem($baseLineHeight);
  scroll-behavior: smooth;

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;
}

body {
  line-height: rem($baseLineHeight);
  opacity: 1 !important;
  overflow-anchor: none;
  font-weight: 300;
  &:not(.javascript) * {
    transition: none !important;
  }
}

iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

address {
  font-style:normal;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend .marginBottom;
  display: block;
  text-rendering: optimizeLegibility;
  hyphens: auto;
  line-height: 130%;
  font-weight: 700;
  @include breakpoint(giant) {
    hyphens: none;
  }

  /* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/

  span {
    display: block;
    color: #7E7E7E;
    font-weight: 300;
    margin-top: 5px;
  }
}

h1,
.h1 {
  font-family: $displayFont;
  font-size: rem($h1Size);
  line-height: 1.2em;
  position: relative;
  padding-left: 1.5rem;
  @include breakpoint(large) {
    font-size: rem(36px);
    padding-left: 2rem;
  }

  span {
    font-size: rem(20px);
    line-height: 1.3em;
    @include breakpoint(large) {
      font-size: rem(26px);
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 9px;
    background: darken($medium, 15%);
    top: 0;
    bottom: 0;
    left: 0;
  }
  body.login & {
    display: inline-block;
  }
}

h2,
.h2 {
  font-size: rem($h2Size);
  line-height: 1.2em;
  position: relative;
  padding-left: 1.5rem;
  @include breakpoint(large) {
    font-size: rem(30px);
    padding-left: 2rem;
  }

  span {
    font-size: rem(16px);
    line-height: 1.3em;
    @include breakpoint(large) {
      font-size: rem(20px);
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 9px;
    background: darken($medium, 15%);
    top: 0;
    bottom: 0;
    left: 0;
  }
}

h3,
.h3 {
  font-size: rem($h3Size);
  @include breakpoint(large) {
    font-size: rem(20px);
  }
}

h4,
.h4 {
  font-size: rem($h4Size);
  @include breakpoint(large) {
    font-size: rem(18px);
  }
}

h5,
.h5 {
  font-size: rem($h5Size);
}

h6,
.h6 {
  font-size: rem($h6Size);
}

.icon {
  margin-right: 5px;
  width: 15px;
  font-size: 15px;
}

dl.openingHours {
  position: relative;
  padding-left: 23px;
  margin-top: rem(22px);
  &:before {
    font-family: $iconFont;
    content: map-get($iconMap, clock);
    position: absolute;
    left: 0;
    top: -1px;
  }
  dt,
  dd {
    display: inline-block;
  }
  dt {
    width: 80px;
    @include breakpoint(large) {
      width: 70px;
    }
    @include breakpoint(huge) {
      width: 80px;
    }
    &:after {
      content: ":";
    }
  }
  dd {
    display: inline-block;
    width: calc(100% - 85px);
  }
}

/**
 * Links
 */
a {
  word-wrap: break-word;
  text-decoration: none;
  .dark-bg & {
    background: 
      linear-gradient(to right, rgba($primary, 1), rgba($primary, 1)),
      linear-gradient(to right, rgba($border, 1), rgba($border, 1));
    background-size: 100% 2px, 0 2px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms, color 400ms;
    color: $light;

    &:hover,
    &:focus {
      background-size: 0 2px, 100% 2px;
      color: $primary;
    }
  }

  img {
    border: none;
  }

  &[href^="tel"] {
    // color: inherit;
    // text-decoration: underline;
  }
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

hr,
.hr {
  display: flex;
  border: none;
  clear: both;
  height: rem(1px);
  margin: 4rem 0 0;
  flex-basis: 100%;
}

ul,
ol {
  list-style: none;

  &.styledList,
  main & {
    li {
      margin-bottom: rem(7px);
      margin-right: rem(7px);
      position: relative;
      padding: 13px 22px;
      padding-left: rem(50px);
      background: $medium;
      display: inline-block;
      @include breakpoint(medium, max) {
        padding: 10px 18px;
        padding-left: rem(45px);
      }
      @include breakpoint(small, max) {
        font-size: rem(14px);
      }
      @include breakpoint(tiny, max) {
        display: block;
      }
  
      &:before {
        content: '';
        background-image: url('/images/layout/listenicon.svg');
        width: 20px;
        height: 29px;
        position: absolute;
        left: 22px;
        background-size: contain;
        background-repeat: no-repeat;
        @include breakpoint(medium, max) {
          width: 16px;
          height: 24px;
          top: 13px;
          left: 18px;
        }
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

.first {
  @include breakpoint(tiny) {
    padding-right: 4px;
  }
}
.second {
  @include breakpoint(tiny) {
    padding-left: 4px;
  }
}

dl {
  &.styledList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      background: rgba($medium, 0.8);
      padding: rem(15px);
    }

    dt {
      padding-bottom: 0;
      font-weight: bold;
    }

    dd {
      padding-top: 0;

      + dt {
        margin-top: rem(10px);
      }

      + dd {
        margin-top: rem(-15px);
      }
    }
  }

  &.lessmargin {
    margin-bottom: 8px !important;
    @include breakpoint(large, max) {
    font-size: 15px;
    }
  }

  &.floatList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }
}

ol {
  &.styledList
  {
    counter-reset: item;
  
    > li {
      counter-increment: item;
  
      &:before {
        content: counter(item) ". ";
      }
    }
    ol {
      counter-reset: item;
      margin-bottom: 0;
  
      > li {
        &:before {
          margin-right: rem(5px);
        }
      }
    }
  }
}

.modalGallery {
  a {
    background: none;
    transition: opacity 0.4s;
    &:hover {
      opacity: 0.6;
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;
  main & {
    border-bottom-left-radius: 50px;
    @include breakpoint(small) {
      border-bottom-left-radius: 100px;
    }
  }

  &[src^='http://cms.'], &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom: 0;
  }

  &[data-src] {
    @extend %lazyloading;
  }
  &.max-height {
    margin-bottom: 2rem;
    object-position: 20% 50%;
    object-fit: cover;
    border-bottom-left-radius: 50px;
    @include breakpoint(small) {
      margin-bottom: 0rem;
      min-height: calc(100% - 2rem);
      border-bottom-left-radius: 100px;
    }
    @include breakpoint(giant) {
      min-height: 100%;
    }
    &.left {
      object-position: 60% 50%;
    }
    &.center {
      object-position: 50% 50%;
    }
    &.right {
      object-position: 70% 50%;
    }
  }
}

/**
 * Tables
 */
main {
  table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: rem($baseFontSize);

    @include breakpoint(small, max) {
      overflow-x: auto;
      display: block;
    }

    caption {
      text-align: left;
      padding: rem($baseGap) rem($baseGap) rem(25px);
      display: table-caption;
      font-weight: bold;
      font-size: 1.2rem;
    }

    thead {
      border-collapse: collapse;
      display: table-header-group;
    }

    tbody {
      width: 100%;
      overflow-x: scroll;
      display: table-row-group;

      tr {
        padding: rem($baseGap) 0;

        &:not(:last-child) {
          border-bottom: rem(1px) solid $medium;
        }
      }
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: rem($baseGap);
      display: table-cell;
      vertical-align: top;
    }

    @include breakpoint(small, max) {
      td {
        min-width: 50vw;
      }
    }
  }
}

.cmsBackend {
  .aos-init {
    opacity: 1 !important;
    transform: translate3d(0, 0, 0) !important;
    z-index: 999 !important;
    position: relative;
  }
}